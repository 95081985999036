import React, { useCallback, useState, FC, useEffect } from 'react';
import axios from 'axios';

interface ChuckProps {
   message: string;
}

interface Wisdom {
   categories: [],
   created_at: string,
   icon_url: string,
   id: string,
   updated_at: string,
   url: string,
   value: string
}

const Chuck: FC<ChuckProps> = ({ message }) => {
   const [count, setCount] = useState(0);
   const [joke, setJoke] = useState("");

   const getJoke = async (): Promise<Wisdom> => {
      const res = await axios.get<Wisdom>("https://api.chucknorris.io/jokes/random");
      return res.data;
   }

   const increment = useCallback(() => {
      setCount(count => count + 1);
   }, [count]);


   useEffect(() => {
      (async () => {
         const joke = await getJoke();
         setJoke(joke.value);
      })();
   }, [count]);

   return (<>
      <h3>{message}</h3>
      <h4>Wisdom # {count}</h4>
      <button onClick={increment}>More</button>
      <br />
      {joke}
   </>);

};

export default Chuck;
